
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';

@Component
export default class RatesNoData extends Vue {
    @Inject(RatesServiceS)
    ratesService!: RatesService;

    handleRefresh() {
        this.ratesService.resetLoading();
    }
}

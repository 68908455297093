
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CiTable, {
    ITableData, ITableConfig, ICell, DATA_TYPE, IIndicators,
} from '@/modules/common/components/ci-table';

import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';
import RatesDocumentItemModel from '../../models/rates-document-item.model';
import RatesDocumentModel from '../../models/rates-document.model';
import RatesTableTooltip from './table-tooltip.vue';
import PRICE_SHOWN from '../../constants/price-shown.constant';
import RatesNoData from './rates-no-data.vue';
import RatesAllService, { RatesAllServiceS } from '../../rates-all.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        CiTable,
        RatesTableTooltip,
        RatesNoData,
    },
    filters: {
        PriceFilter,
    },
})
export default class RatesTable extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(RatesAllServiceS) private ratesAllService!: RatesAllService;

    disabledColumns: string[] = [];
    tooltipDay: Day = 1;
    tooltipFocusElement: HTMLElement | null = null;
    tooltipHotelName: string = '';
    tooltipPreviewValues: { [k: string]: any } = {};
    tooltipIndicators: IIndicators = {
        mealType: false,
        numberOfGuests: false,
        cancellation: false,
        losRestriction: false,
        occupancy: false,
    };

    mounted() {
        this.scrollToActiveRow();
    }

    transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);

        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    getRoom(day: Day, hotelId: number | string) {
        return this.ratesService.getRoom(day, hotelId);
    }

    demand(day: Day) {
        const demandValue = this.ratesService.getDemand(day);
        if (demandValue === null) return '';
        return demandValue;
    }

    occupancy(day: Day) {
        const occupancyValue = this.ratesService.getOccupancy(day);
        if (occupancyValue === null) return '';
        return occupancyValue;
    }

    medianPrice(day: Day) {
        const price = this.ratesService.isAllChannelsMode
            ? this.ratesAllService.getAverageRoomsPrice(day)
            : this.ratesService.getMedianPrice(day);
        return price ? PriceFilter(price) : '';
    }

    myHotelPrice(day: Day) {
        if (this.ratesService.isLoading) return null;

        if (this.ratesService.isAllChannelsMode) {
            return null;
        }

        const { currentHotelId } = this.userService;

        const { data } = this.ratesService;
        if (day && currentHotelId) {
            const { exchangeRate } = (data || {}) as RatesDocumentModel;

            return this.ratesService.getPrice(day, currentHotelId)! * exchangeRate;
        }
        return null;
    }

    getDiff(day: Day) {
        const compsetPrice = this.ratesService.getCompsetPrice(day);
        const myHotelPrice = this.myHotelPrice(day);

        if (!myHotelPrice || !compsetPrice) {
            return {
                value: '',
                color: '',
            };
        }

        const isBadColor = this.ratesService.getTableAssessment(compsetPrice, day) === ASSESSMENTS_TYPES.BAD;
        const result = {
            value: '',
            color: isBadColor ? 'red' : 'grey',
        };

        if (this.showPriceDiff) {
            const diffInNumber = Math.abs(myHotelPrice - compsetPrice);
            result.value = diffInNumber ? `${this.currency} ${PriceFilter(diffInNumber, 0)}` : '';

            return result;
        }

        const diffInPercent = this.ratesService.getCompetitionPercent(day);
        result.value = diffInPercent ? `${Math.round(diffInPercent * 100)}%` : '';

        return result;
    }

    priceColor(day: Day, price: number) {
        const assessmentType = price ? this.ratesService.getTableAssessment(price, day) : null;

        if (assessmentType === ASSESSMENTS_TYPES.BAD) {
            return '#E7472D';
        }

        if (assessmentType === ASSESSMENTS_TYPES.GOOD) {
            return '#01B875';
        }

        return null;
    }

    getRoomData(day: Day, rooms: HotelRooms, hotelId: number | string) {
        if (!rooms[hotelId]) return null;

        const data = {
            rank: '',
            price: '',
            style: {},
            hasPrice: false,
            isBasic: false,
            isNetCalc: false,
        };

        if (this.ratesService.isOutOfRange()) {
            data.price = 'Out Of Range';
            return data;
        }

        if (this.ratesService.isNoData(day)) {
            data.price = 'No Data';
            return data;
        }

        if (this.ratesService.isNA(day, hotelId)) {
            data.price = 'N/A';
            return data;
        }

        if (this.ratesService.isSoldOut(day, hotelId)) {
            data.price = 'Sold Out';
            return data;
        }

        const room = rooms[hotelId];
        const price = this.ratesService.switchPrice(rooms[hotelId]) as number;

        const isNet = this.documentFiltersService.priceShown === PRICE_SHOWN.NET;
        data.hasPrice = true;
        data.price = PriceFilter(price);
        data.rank = room ? `(${String(room!.priceRank || '')})` : '';
        data.style = { color: this.priceColor(day, price) };
        data.isBasic = !!room && room.isBasic;
        data.isNetCalc = isNet && !!room?.isNetCalc;

        return data;
    }

    losRestriction(day: Day, hotelId: number | string) {
        const losRestriction = this.ratesService.getHotelLosRestriction(day, hotelId);
        if (!losRestriction) {
            return false;
        }

        return losRestriction;
    }

    indicators(day: Day, hotelId: number | string, hotelRooms: HotelRooms) {
        const indicators: IIndicators = {
            mealType: false,
            numberOfGuests: false,
            cancellation: false,
            losRestriction: false,
            occupancy: false,
        };

        const room = hotelRooms[hotelId];

        if (!room) {
            return indicators;
        }

        const mealType = this.mealTypesService.getMealType(room.mealTypeId) || null;

        const hasMealType = this.ratesService.hasRoomMealType(day, hotelId);
        if (hasMealType) {
            indicators.mealType = {
                name: 'Meal Type',
                value: mealType ? mealType.displayName : '',
                icon: 'icon-meal-3',
            };
        }

        const hasOccupancy = !this.ratesService.hasRoomSameOccupancy(day, hotelId);
        if (hasOccupancy) {
            indicators.occupancy = {
                name: 'Guest Number',
                value: String(room.occupancy),
                icon: 'icon-Guest-2-01',
            };
        }

        const hasCancelation = this.ratesService.hasRoomMultipleCancellation(day, hotelId);
        if (hasCancelation) {
            indicators.cancellation = {
                name: 'Cancellation',
                value: 'Multiple',
                icon: 'icon-cancelation2',
            };
        }
        const hasLosRestriction = this.losRestriction(day, hotelId);
        if (hasLosRestriction) {
            indicators.losRestriction = {
                name: 'Los restriction',
                value: String(hasLosRestriction),
                icon: 'icon-los2',
            };
        }

        return indicators;
    }

    handleRowClick(day: Day) {
        if (this.userService.currentHotelId) {
            const hotelId = String(this.userService.currentHotelId);

            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { hotelId, day: String(day) },
            });
        }
    }

    setTooltipData(day: Day, focusElement: HTMLElement) {
        this.tooltipFocusElement = focusElement;
        this.tooltipDay = day;
    }

    resetTooltipData() {
        this.tooltipFocusElement = null;
    }

    updateDisabledColumnsList(disabledColumns: string[]) {
        this.disabledColumns = disabledColumns;
    }

    generatePreviewData(day: Day) {
        const params = {
            Events: () => ({
                component: CalendarEventsContainer,
                props: {
                    day,
                    emptyText: 'No events',
                },
            }),
            Demand: () => {
                const demand = this.demand(day);

                return {
                    value: demand ? `${demand}%` : 'No Data',
                };
            },
            OTB: () => {
                const occupancy = this.occupancy(day);

                return {
                    value: occupancy ? `${occupancy}%` : 'No Data',
                };
            },
            Median: () => {
                const median = this.medianPrice(day);
                const value = median
                    ? this.currency + median
                    : 'No Data';

                return {
                    value,
                };
            },
            Diff: () => ({
                value: this.getDiff(day).value,
            }),
        } as {
            [k: string]: () => {
                component?: any;
                props?: { [k: string]: any };
                value?: any,
            }
        };

        const entries = this.disabledColumns.map(key => {
            const value = params[key]();
            return [key, value];
        });

        return Object.fromEntries(entries);
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange();
    }

    get isLoading() {
        const state = this.ratesService.isLoading;

        if (!state) {
            this.scrollToActiveRow();
        }

        return state;
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get currency(): string | null {
        const { currency } = this.ratesService;
        return currency ? this.helperService.currencySymbol(currency) : '';
    }

    get ratesTableConfig(): ITableConfig {
        const isCheapest = this.ratesService.settings.provider === 'cheapest';

        return {
            height: '100%',
            width: '100%',
            cellSize: [{
                width: !this.ratesService.isAllChannelsMode
                    ? ['90px', '120px', '100px', '100px', '120px', '120px', '200px']
                    : ['90px', '120px', '150px'],
                height: ['50px'],
            }, {
                width: [isCheapest ? '250px' : '200px'],
                height: ['50px'],
            }],
        };
    }

    get ratesTableData(): ITableData {
        const { currentHotelId } = this.userService;
        const competitors: (string | number)[] = this.ratesService.isAllChannelsMode
            ? this.ratesService.availableProviders
            : this.documentFiltersService.competitors || [];

        const { days } = this.documentFiltersService;

        if (!currentHotelId) {
            return [];
        }

        const NO_DATA_LABEL = this.ratesService.isOutOfRange()
            ? 'Out Of Range'
            : 'No Data';

        const rooms: HotelRooms[] = days.map(day => {
            if (this.ratesService.isAllChannelsMode) {
                const rooms = this.ratesService.getCompetitorsRooms(day);

                return rooms;
            }

            const hotelRooms = competitors
                .reduce((acc, competitor) => {
                    const room = this.getRoom(day, competitor);
                    if (!room) return acc;

                    acc[competitor] = room;
                    return acc;
                }, {} as Record<string | number, RatesDocumentItemModel>);

            const room = this.getRoom(day, currentHotelId);

            if (room) {
                hotelRooms[currentHotelId] = room;
            }

            return hotelRooms;
        });

        const handleCellHover = (day: Day, hotelId: number | string = currentHotelId) => (e: MouseEvent) => {
            const focusElement = e.currentTarget! as unknown as HTMLElement;
            this.tooltipIndicators = this.indicators(day, hotelId, rooms[day - 1]);

            this.tooltipHotelName = !this.ratesService.isAllChannelsMode
                ? this.hotelsService.getHotelName(+hotelId)
                : this.providersService.getProviderLabel(String(hotelId));

            this.tooltipPreviewValues = hotelId === currentHotelId
                ? this.generatePreviewData(day)
                : {};

            this.setTooltipData(day, focusElement);
        };

        return [
            {
                isSticky: true,
                boldRow: this.documentFiltersService.todayDate,
                columns: [
                    {
                        title: 'Date',
                        data: days.map(day => ({
                            value: this.transformDate(day),
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: 'Events',
                        dataType: DATA_TYPE.EVENT,
                        data: days.map(day => ({
                            onClick: () => this.handleRowClick(day),
                        })),
                    },
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: 'Demand',
                            dataType: DATA_TYPE.DEMAND,
                            data: days.map(day => {
                                const demand = this.demand(day);
                                return {
                                    value: demand ? `${demand}%` : '',
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        } : null,
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: 'OTB',
                            dataType: DATA_TYPE.OCCUPANCY,
                            data: days.map(day => {
                                const occupancy = this.occupancy(day);
                                return {
                                    value: occupancy ? `${occupancy}%` : '',
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        } : null,
                    {
                        title: this.ratesService.isAllChannelsMode
                            ? 'All (Average)'
                            : 'Median',
                        data: days.map(day => {
                            const median = this.medianPrice(day);
                            return {
                                value: median ? `${this.currency} ${median}` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    },
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: 'Diff',
                            data: days.map(day => {
                                const { value, color } = this.getDiff(day);
                                return {
                                    value,
                                    style: { color },
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        }
                        : null as any,
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: this.hotelsService.getHotelName(currentHotelId),
                            titleStyle: { color: '#00759e' },
                            dynamicColumns: ['Events', 'Demand', 'OTB', 'Median', 'Diff'],
                            data: days.map(day => {
                                const {
                                    rank, price, hasPrice,
                                    isBasic, isNetCalc,
                                } = this.getRoomData(day, rooms[day - 1], currentHotelId) || {
                                    rank: null,
                                    price: this.ratesService.isNoData(day) ? NO_DATA_LABEL : 'N/A',
                                    hasPrice: false,
                                    isBasic: false,
                                };

                                const indicators = this.indicators(day, currentHotelId, rooms[day - 1]);
                                const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                                    ? this.ratesService.getRoomProviders(day, currentHotelId)
                                    : undefined;

                                return {
                                    value: `${hasPrice ? `${this.currency} ` : ''}${price}${isNetCalc ? '*' : ''}`,
                                    titleArg: isNetCalc ? 'This value was calculated based on Net rates settings' : undefined,
                                    before: rank,
                                    provider,
                                    indicators,
                                    img: isBasic ? BOOKING_BASIC_ICON : null,
                                    onClick: () => this.handleRowClick(day),
                                    onHover: handleCellHover(day),
                                    onLeave: this.resetTooltipData.bind(this),
                                } as ICell;
                            }),
                        }
                        : null as any,
                ].filter(Boolean),
            },
            {
                boldRow: this.documentFiltersService.todayDate,
                columns: competitors.map(competitorId => ({
                    title: this.ratesService.isAllChannelsMode
                        ? this.providersService.getProviderLabel(String(competitorId))
                        : this.hotelsService.getHotelName(+competitorId),
                    data: days.map(day => {
                        const {
                            rank, price, style, hasPrice,
                            isBasic, isNetCalc,
                        } = this.getRoomData(day, rooms[day - 1], competitorId) || {
                            rank: null,
                            price: this.ratesService.isNoData(day) ? NO_DATA_LABEL : 'N/A',
                            style: null,
                            hasPrice: false,
                            isBasic: false,
                        };
                        const indicators = this.indicators(day, competitorId, rooms[day - 1]);
                        const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                            ? this.ratesService.getRoomProviders(day, +competitorId)
                            : undefined;

                        return {
                            value: `${hasPrice ? `${this.currency} ` : ''}${price}${isNetCalc ? '*' : ''}`,
                            titleArg: isNetCalc ? 'This value was calculated based on Net rates settings' : undefined,
                            before: rank,
                            provider,
                            img: isBasic ? BOOKING_BASIC_ICON : null,
                            indicators,
                            style,
                            onClick: () => this.handleRowClick(day),
                            onHover: handleCellHover(day, competitorId),
                            onLeave: this.resetTooltipData.bind(this),
                        } as ICell;
                    }),
                    isFolding: true,
                })),
            },
        ];
    }

    scrollToActiveRow() {
        const { table } = this.$refs as { table: CiTable };
        const { todayDate } = this.documentFiltersService;

        if (table && todayDate) {
            table.scrollToRow(todayDate);
        }
    }
}
